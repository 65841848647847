export const theme = {
  colors: {
    black: '#212121',
    gray: '#9B9FAA',
    orange: '#FC842D',
    blue: '#264061',
    backgroundGray: '#1a1a1a',
    white: '#ffffff',
    hover: '#f07116',
    filter: 'none',
  },
  fonts: {
    body: 'VerdanaBold, sans-serif',
    secondary: 'VerdanaRegular, sans-serif',
    tertiary: 'GothamProBold',
  },
  space: [0, 4, 8, 16, 20, 32, 64, 128, 256, 512].map(n => n + 'px'),
  fontSizes: [14, 18, 24, 34, 36].map(n => n + 'px'),
  lineHeights: {
    body: 1.2,
    heading: 1.125,
  },
  borders: {
    none: 'none',
    normal: '1px solid #E0E0E0',
    header: '1px solid #E0E0E0',
    btn: '2px solid #FC842D',
    hover: '2px solid #f07116',
  },
  btnBefore: {
    position: undefined,
    top: undefined,
    left: undefined,
    width: undefined,
    height: undefined,
    background: undefined,
    borderRadius: undefined,
    boxShadow: undefined,
  },
  btnAfter: {
    position: undefined,
    top: undefined,
    left: undefined,
    width: undefined,
    height: undefined,
    background: undefined,
    borderRadius: undefined,
    boxShadow: undefined,
  },
};


export const nightModeTheme = {
  colors: {
    black: '#ffffff',
    gray: '#cccccc',
    orange: '#fc842d',
    blue: '#264061',
    backgroundGray: '#000000',
    white: '#ffffff',
    hover: '#ff6600',
    filter: 'none',
  },
  fonts: {
    body: 'VerdanaBold, sans-serif',
    secondary: 'VerdanaRegular, sans-serif',
    tertiary: 'GothamProBold',
  },
  space: [0, 4, 8, 16, 20, 32, 64, 128, 256, 512].map(n => n + 'px'),
  fontSizes: [14, 18, 24, 34, 36].map(n => n + 'px'),
  lineHeights: {
    body: 1.2,
    heading: 1.125,
  },
  borders: {
    none: 'none',
    normal: '1px solid #E0E0E0',
    header: '1px solid #E0E0E0',
    btn: '2px solid #FC842D',
    hover: '2px solid #ff6600',
  },
  btnBefore: {
    position: undefined,
    top: undefined,
    left: undefined,
    width: undefined,
    height: undefined,
    background: undefined,
    borderRadius: undefined,
    boxShadow: undefined,
  },
  btnAfter: {
    position: undefined,
    top: undefined,
    left: undefined,
    width: undefined,
    height: undefined,
    background: undefined,
    borderRadius: undefined,
    boxShadow: undefined,
  },
};
